import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as thankyouStyles from "../styles/thankyou.module.css"

const Thankyou = () => {
  return (
    <Layout>
      <SEO title="Thank you" />
      <section className={thankyouStyles.section}>
        <h1>Thank you!</h1>
        <p>Your submission is received and we will contact you soon.</p>
      </section>
    </Layout>
  )
}

export default Thankyou
